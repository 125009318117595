import React from "react";
//import { langVersion } from "../../accessors";
import './PageHeader.scss';
import { takeUrl } from '../../accessors';


const PageHeader = ({pageTitle}) => {

 return <section id={takeUrl()} className="page-header">
    <h1 className="page__title">{pageTitle}</h1>
    <div className="triangle"></div>
    <div className="triangle"></div>
  </section>
}

export default PageHeader