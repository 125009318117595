import React from "react";
import "./BookLink.scss";

const LinkBook = ({ collection }) => {

  const addElems = (collection) => {

    return collection.map(item => {
      //console.log(item);
      if (item.prgh !== undefined) { return <p className="info__text" key={(item.prgh).toString()}>{typeof item.prgh === 'string' ? item.prgh : addElems(item.prgh)}</p> };
      if (item.ul !== undefined) { return <ul className="article__list" key={(item.ul).toString()}>{typeof item.ul === 'string' ? item.ul : addElems(item.ul)}</ul> };
      if (item.li !== undefined) { return <li className="list__item" key={(item.li).toString()}>{typeof item.li === 'string' ? item.li : addElems(item.li)}</li> };
      if (item.italic !== undefined) return <i className="text--italic" key={(item.italic).toString()}>{item.italic}</i>;
      if (item.bold !== undefined) return <b className="text--bold" key={(item.bold).toString()}>{item.bold}</b>;
      if (item.boldItalic !== undefined) return <span className="text--bold-italic" key={(item.boldItalic).toString()}>{item.boldItalic}</span>;
      if (item.sup !== undefined) return <sup className="text--sup" key={(item.sup).toString()}>{item.sup}</sup>;
      if (item.a !== undefined) return <a className="text--link" href={item.a} key={(item.a).toString()} title={"Link to " + item.a}>{item.text !== undefined ? item.text : item.a}</a>;
      if (item.mail !== undefined) return <a className="text--link" href={"mailto:" + item.mail} key={(item.mail).toString()} title={"Mail to " + item.mail}>{item.text !== undefined ? item.text : item.mail}</a>;
      if (item.text !== undefined) return item.text;
      if (item.br !== undefined) return <br></br>;
    })
  };

  return <div className="book-link__container">
    {collection.map((item, index) => {

      if (item.div !== undefined) {
        return <div key={(item.div).toString()} className="book-description">
          {addElems(item.div)}
        </div>
      }

      if (item.link !== undefined) {
        return <a
          className="book-link"
          href={item.link}
          key={(item.link).toString()}
          title={"Link to " + item.text}
          style={{ backgroundImage: `url("${item.img}")` }}
          target="_blank">
            <span className="book-info">pdf</span>
        </a>
      };
    }
    )}
  </div>
}

export default LinkBook;