import React from "react"
import "./DocLink.scss"
import { langVersion } from "../../accessors"

const DocLink = ({ collection, location }) => {
  return (
    <div className="pdf-link__container">
      {collection.map((item, index) => {
        if (item.pdf !== undefined) {
          return (
            <div key={item.pdf} className="pdf-box">
              <a
                className="pdf__link"
                href={location + item.pdf}
                title={langVersion("Otwórz dokument", "Open document")}
                target="_blank"
              >
                <img
                  className="pdf__bg"
                  src={location + item.src}
                  alt={item.alt}
                ></img>
                <span className="pdf-info">pdf</span>
                <span className="pdf-info--hidden">
                  {langVersion("Otwórz", "Open")}
                </span>
              </a>
              <p className="article__text">{item.text}</p>
            </div>
          )
        }
        if (item.doc !== undefined) {
          return (
            <div key={item.doc} className="pdf-box">
              <a
                className="pdf__link"
                href={location + item.doc}
                title={langVersion("Otwórz dokument", "Open document")}
                target="_blank"
              >
                <img
                  className="pdf__bg"
                  src={location + item.src}
                  alt={item.alt}
                ></img>
                <span className="pdf-info">doc</span>
                <span className="pdf-info--hidden">
                  {langVersion("Otwórz", "Open")}
                </span>
              </a>
              <p className="article__text">{item.text}</p>
            </div>
          )
        }
      })}
    </div>
  )
}

export default DocLink
